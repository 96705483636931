import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <main className="no_p">
      <span className="shape"></span>

      <section className="page-404-top-back">
        <span>404</span>
      </section>

      <section className="page-404-main">
        <div className="container">
          <p>
            Dessverre er siden du prøver å <br /> få tilgang til, ikke
            tilgjengelig eller eksisterer ikke
          </p>
          <Link to="/" className="btn_custom">
            Gå tilbake til hovedsiden
          </Link>
        </div>
      </section>
    </main>
  </Layout>
)

export default NotFoundPage
